import { Copy01Icon, FloppyDiskIcon } from 'hugeicons-react'
import React from 'react'
import CustomButton from '../../Button'

const NewCategoryForm = () => {
    return (
        <div className='form-container'>
            <form>
                <div className='form-group'>
                    <label htmlFor='categoryName'>Category Name:</label>
                    <input type='text' id='categoryName' className='form-control' placeholder='Enter category name' />
                </div>
                <div className="form-group">
                    <label htmlFor='link'>Link:</label>
                    <div className="link-field">
                        <input disabled type='text' id='link' className='form-control' placeholder='Link is auto generated' />
                        <div type='button' className="button outline-button">< Copy01Icon size={18} />Copy</div>
                    </div>
                </div>
                <div className='form-group'>
                    <label htmlFor='description'>Description:</label>
                    <textarea id='description' className='form-control' placeholder='Enter description' rows='5'></textarea>
                </div>

            </form>
            <CustomButton
                iconClass={<FloppyDiskIcon />}
                buttonClasses={'primary-button'}
                buttonText={'Save category'} />
        </div>
    )
}

export default NewCategoryForm

import React, { useState } from 'react';
import mediaAPI from '../../../services/mediaAPI';
import { Upload01Icon } from 'hugeicons-react';
import LoadingIcon from '../../../componets/LoadingIcon';

const ProfilePictureContainer = ({ featuredImage, setFeaturedImage, setErrorMessage, setSuccessMessage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();
    formData.append('files', selectedFile);

    try {
      setIsLoading(true)
      const response = await mediaAPI.post('/upload/', formData);
      if (response.status === 200) {
        console.log("data :", response.data)
        setFeaturedImage({ ...featuredImage, url: response.data.urls });
        setSuccessMessage('Featured image uploaded successfully!');
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error uploading profile image:', error.message);
      setErrorMessage('Error uploading profile image. Please try again.');
      setIsLoading(false)
    }
  };

  return (
    <div className='card'>
      {featuredImage && featuredImage.url ?
        <img className='featured-image' src={featuredImage.url} alt="Profile" />
        :
        <>
          <div className="upload-image-container">
            <input type="file" onChange={handleFileChange} />
            <button className='primary-button' onClick={handleUpload}>{isLoading ? <LoadingIcon size={18} /> : <><Upload01Icon size={20} /> Upload Image</>}</button>
          </div>
        </>
      }
    </div>
  );
};

export default ProfilePictureContainer;

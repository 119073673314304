import React, { useEffect, useState } from 'react'
import PageContainer from '../PageContainer'
import api from '../../../services/api';
import NewPodcastForm from '../../../componets/forms/podcasts/NewPodcastForm';
import { ArrowLeft01Icon, ArrowRight01Icon, Cancel01Icon, PlusSignIcon, Search01Icon, SquareIcon } from 'hugeicons-react';
import CustomButton from '../../../componets/Button';

const PodcastsPageContent = () => {
    const [podcasts, setPodcasts] = useState([])
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;  // Number of items per page

    // Calculate the total number of pages
    const totalPages = Math.ceil(podcasts.length / itemsPerPage);

    // Get current items based on currentPage
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = podcasts?.slice(indexOfFirstItem, indexOfLastItem);
    const [showNewPodcastForm, setShowNewPodcastForm] = useState(false);

    // Function to toggle new Podcast form visibility
    const toggleNewPodcastForm = () => {
        setShowNewPodcastForm(!showNewPodcastForm);
    }

    // Function to handle page change
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const fetchPodcasts = async () => {
        try {
            const response = await api.get(`/podcasts/`)
            if (response.status === 200) {
                console.log(response)
                setPodcasts(response.data)
            }
        } catch (error) {
            console.error(error)
        }
    }



    useEffect(() => {
        fetchPodcasts()
    }, [])
    return (
        <div className='articles-page'>
            <h2>Categories</h2>

            <div className="search-filters">
                {/* <div className="search">
                    <input type="text" placeholder="Search categories..." />
                    <button className='outline-button'>
                        Search
                        <Search01Icon size={18} />

                    </button>
                </div> */}
                <div className="filters-create">
                    <CustomButton
                        clickEvent={toggleNewPodcastForm}
                        buttonClasses={'primary-button'}
                        iconClass={<PlusSignIcon size={20} />}
                        buttonText={'Create a podcast'}
                    />
                </div>
            </div>

            <div className="articles-list">
                {/* table starts */}
                <table>
                    <thead>
                        <tr>
                            <th><SquareIcon /> </th>
                            <th>Title</th>
                            <th>Host</th>
                            <th>Rating</th>
                            <th>Category</th>

                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((pod, index) => (
                            <tr key={index}>
                                <td><SquareIcon /></td>
                                <td>{pod.title}</td>
                                <td>{pod.host?.user?.first_name} {pod.host?.user?.last_name}</td>
                                <td>{pod.rating}</td>
                                <td>{pod.category.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* table ends */}

                {/* Pagination controls */}
                <div className="pagination">

                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="primary-button small-button">
                        <ArrowLeft01Icon size={18} />
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="primary-button small-button">
                        Next
                        <ArrowRight01Icon size={18} />
                    </button>
                </div>
            </div>
            {
                showNewPodcastForm &&
                <div className="pop-up">
                    <div className="popup-content">
                        <h2>New Podcast</h2>
                        <Cancel01Icon onClick={toggleNewPodcastForm} size={24} className='close-icon' />
                        <NewPodcastForm />
                    </div>
                </div>
            }
        </div>
    );

}
const PodcastsPage = () => {
    return (
        <PageContainer content={<PodcastsPageContent />} />
    )
}

export default PodcastsPage

export const podcastsList = [
    {
        id: 1,
        title: "Tech Trends Today",
        host: "Alex Carter",
        description: "Exploring the latest in technology and innovation.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 56,
        category: "Technology",
        rating: 4.8,
        url: "https://substack.com/",
    },
    {
        id: 2,
        title: "Startup Stories",
        host: "Sophia Zhang",
        description: "Inspiring journeys of entrepreneurs building their dreams.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 34,
        category: "Business",
        rating: 4.5,
        url: "https://substack.com/",
    },
    {
        id: 3,
        title: "Mindful Moments",
        host: "Ryan Taylor",
        description: "Guided meditations and insights for a balanced life.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 48,
        category: "Wellness",
        rating: 4.7,
        url: "https://substack.com/",
    },
    {
        id: 4,
        title: "History Untold",
        host: "Emma Knight",
        description: "Uncovering lesser-known stories from the past.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 22,
        category: "History",
        rating: 4.9,
        url: "https://substack.com/",
    },
    {
        id: 5,
        title: "Sports Zone Weekly",
        host: "Mike Anderson",
        description: "Weekly analysis and updates on the world of sports.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 77,
        category: "Sports",
        rating: 4.6,
        url: "https://substack.com/",
    },
    {
        id: 6,
        title: "Code & Coffee",
        host: "Tanya Smith",
        description: "A casual chat on coding, development, and tech careers.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 41,
        category: "Programming",
        rating: 4.8,
        url: "https://substack.com/",
    },
    {
        id: 7,
        title: "Cosmic Conversations",
        host: "David Lee",
        description: "Discussions about space, astronomy, and the universe.",
        thumbnail: "https://images.unsplash.com/photo-1531651008558-ed1740375b39?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 29,
        category: "Science",
        rating: 4.9,
        url: "https://substack.com/",
    },
    {
        id: 8,
        title: "Cinema Chat",
        host: "Lily Brooks",
        description: "Deep dives into movies, TV shows, and entertainment.",
        thumbnail: "https://images.unsplash.com/photo-1488376986648-2512dfc6f736?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        episodes: 65,
        category: "Entertainment",
        rating: 4.7,
        url: "https://substack.com/",
    },
];
import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { Cancel01Icon, PlusSignIcon } from 'hugeicons-react';
import { authorsList } from '../../../constants/authors';
import CustomSelectInput from '../../../componets/inputs/CustomSelect';
import { nameInitials } from '../../../services/namesInitials';

const AuthorsContainer = ({ selectedAuthors, setSelectedAuthors }) => {
    const [isLoading, setIsLoading] = useState(false);
    // Messages
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [authors, setAuthors] = useState([]);
    const [newAuthorName, setNewAuthorName] = useState('');

    // Fetch authors when the component mounts

    useEffect(() => {

        const fetchAuthors = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/dashboard/authors/`)
                if (response.status === 200) {
                    setAuthors(response.data.map(author => ({
                        value: `${author.id}`,
                        label: `${author.first_name} ${author.last_name}`,
                    })));
                }
            } catch (error) {
                console.error(error);
                setErrorMessage("Failed to fetch authors. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        }
        fetchAuthors();

    }, []);


    const handleSelectedAuthor = (option) => {
        // if author is not in selectedAuthors, add it
        console.log("selected option", option);
        if (!selectedAuthors.includes(option)) {
            setSelectedAuthors([...selectedAuthors, option]);
            setAuthors(authors.filter((a) => a !== option));
            setNewAuthorName("");
            localStorage.setItem("selectedAuthors", JSON.stringify(selectedAuthors));
        }
    };

    const handleRemoveAuthor = (author) => {
        setSelectedAuthors(selectedAuthors.filter((a) => a !== author));
        setAuthors(authors.filter((a) => a !== author));
    };


    return (
        <div className="card">
            <h4>Authors</h4>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {/* Selected authors */}
            <div className="selected-authors form-choices">
                {selectedAuthors && selectedAuthors.map((author, index) => (
                    <div className="author-card">
                        <div className="name-icon">
                            <div className="name-initials">
                                <span>{nameInitials(`${author.label}`)}</span>
                            </div>
                            <h5>{author.label}</h5>
                        </div>

                        <Cancel01Icon color='red' size={18} onClick={() => handleRemoveAuthor(author)} />
                    </div>
                ))}
            </div>

            {/* Author Input with Datalist */}
            {
                isLoading
                    ? 'Loading...'

                    : <form className="form-group">
                        <label htmlFor="authorInput">Select or type an author:</label>
                        <CustomSelectInput
                            handleSelectedOptions={handleSelectedAuthor}
                            selectedOptions={selectedAuthors}
                            options={authors}
                            placeholder={'Select author'} />
                        <div className="button outline-button small-button" onClick={handleSelectedAuthor}>
                            <PlusSignIcon size={18} />
                            New author
                        </div>

                    </form>
            }
        </div>
    );
};

export default AuthorsContainer;

import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export const TINY_KEYS = process.env.REACT_APP_TINY_KEYS;

const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)

export default api

export const cleanedData = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
            acc[key] = value;
        }
        return acc;
    }, {});
};
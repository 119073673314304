import React, { useEffect, useState } from 'react'
import PageContainer from './PageContainer'
import CustomButton from '../../componets/Button'
import { ArrowLeft01Icon, ArrowRight01Icon, PlusSignIcon, SquareIcon } from 'hugeicons-react'

import '../../assets/scss/articles/articles.css'
import api from '../../services/api'

const DashboardPageContent = () => {
    // Pagination state
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [articles, setArticles] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;  // Number of items per page

    // Calculate the total number of pages
    const totalPages = Math.ceil(articles.length / itemsPerPage);

    // Get current items based on currentPage
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = articles.slice(indexOfFirstItem, indexOfLastItem);

    // Function to handle page change
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        const fetchArticles = async () => {

            try {
                const response = await api.get(`/dashboard/blogs/`)
                if (response.status === 200) {
                    setArticles(response.data)
                    localStorage.setItem('articles', JSON.stringify(response.data))
                    setIsLoading(false)
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || "Error getting articles")
                } else {
                    setErrorMessage("Unknown error getting articles")
                }
            }
        }

        fetchArticles()
    }, [])

    return (
        <div className='articles-page'>
            <h2>Articles</h2>

            <div className="search-filters">
                <div className="search">
                    <input type="text" placeholder="Search articles..." />
                    <button>Search</button>
                </div>
                <a className='button primary-button' href="/articles/create/"><PlusSignIcon size={20} />Create article</a>
            </div>

            <div className="articles-list">
                {/* table starts */}
                <table>
                    <thead>
                        <tr>
                            <th><SquareIcon /> </th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Views</th>
                            <th>Comments</th>
                            <th>Date Published</th>
                            <th>Clicks Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((article, index) => (
                            <tr key={index}>
                                <td><SquareIcon /></td>
                                <td>{article.title}</td>
                                <td>{article.category}</td>
                                <td>{article.views}</td>
                                <td>{article.comments}</td>
                                <td>{article.date_published}</td>
                                <td>{article.clicks_count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* table ends */}

                {/* Pagination controls */}
                <div className="pagination">

                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="primary-button small-button">
                        <ArrowLeft01Icon size={18} />
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="primary-button small-button">
                        Next
                        <ArrowRight01Icon size={18} />
                    </button>
                </div>
            </div>
        </div>
    );
};


const DashboardPage = () => {
    return (
        <PageContainer content={<DashboardPageContent />} />
    )
}

export default DashboardPage

import React, { useEffect, useState } from 'react'
import api from '../../../services/api';
import { Cancel01Icon, PlusSignIcon } from 'hugeicons-react';
import { categoriesList } from '../../../constants/categories';
import CustomSelectInput from '../../../componets/inputs/CustomSelect';
const CategoriesContainer = ({ selectedCategories, setSelectedCategories }) => {
    const [isLoading, setIsLoading] = useState(false);
    // Messages
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [categories, setCategories] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState('');

    // Fetch categories when the component mounts
    useEffect(() => {
        setIsLoading(true)
        const fetchCategories = async () => {
            try {
                const response = await api.get(`/dashboard/categories/`)
                if (response.status === 200) {
                    setCategories(response.data.map(category => ({
                        value: category.id,
                        label: category.name,
                    })));
                    setIsLoading(false);
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error while fetching categories');
                } else {
                    setErrorMessage('Unknown error while fetching categories');
                    console.error(error);
                    setIsLoading(false);
                }
            }
        }
        fetchCategories();
    }, []);

    const handleSelectedCategory = (option) => {
        if (!selectedCategories.includes(option) && !selectedCategories.includes(option)) {
            setSelectedCategories([...selectedCategories, option]);
            setNewCategoryName("");
        }
    };


    const handleRemoveCategory = (categoryToRemove) => {
        setSelectedCategories(selectedCategories.filter((category) => category !== categoryToRemove));
    };


    return (
        <div className="card">
            <h4>Categories</h4>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {/* Category Input with Datalist */}
            <div className="selected-categories form-choices">
                {selectedCategories && selectedCategories.map((category, index) => (
                    <div className="selected-category choice" key={index}>
                        {category.label}
                        <Cancel01Icon color='red' size={18} onClick={() => handleRemoveCategory(category)} />
                    </div>
                ))}
            </div>
            {
                isLoading
                    ? 'Loading...'
                    : <form className="form-group">
                        <label htmlFor="categoryInput">Select or type a category:</label>
                        <CustomSelectInput
                            selectedOptions={selectedCategories}
                            placeholder={'Select a category'}
                            options={categories}
                            handleSelectedOptions={handleSelectedCategory} />

                        <div className="button outline-button small-button" onClick={handleSelectedCategory}>
                            <PlusSignIcon size={18} />
                        </div>

                    </form>
            }

        </div>
    );
};

export default CategoriesContainer;
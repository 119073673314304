export const articles = [
    {
        "title": "Introduction to Web Development",
        "category": "Technology",
        "views": 1200,
        "comments": 23,
        "date_published": "2024-10-01",
        "clicks_count": 310
    },
    {
        "title": "Mastering React Hooks",
        "category": "Development",
        "views": 950,
        "comments": 12,
        "date_published": "2024-09-30",
        "clicks_count": 215
    },
    {
        "title": "The Future of AI in Healthcare",
        "category": "AI",
        "views": 1400,
        "comments": 32,
        "date_published": "2024-09-28",
        "clicks_count": 500
    },
    {
        "title": "A Guide to Cloud Storage Solutions",
        "category": "Cloud Computing",
        "views": 850,
        "comments": 8,
        "date_published": "2024-09-27",
        "clicks_count": 170
    },
    {
        "title": "Best Practices for API Design",
        "category": "Development",
        "views": 620,
        "comments": 15,
        "date_published": "2024-09-25",
        "clicks_count": 134
    },
    {
        "title": "Exploring Python 3.11 Features",
        "category": "Programming",
        "views": 780,
        "comments": 10,
        "date_published": "2024-09-24",
        "clicks_count": 195
    },
    {
        "title": "Top 10 JavaScript Frameworks for 2024",
        "category": "Technology",
        "views": 1600,
        "comments": 45,
        "date_published": "2024-09-23",
        "clicks_count": 640
    },
    {
        "title": "DevOps: Tools and Strategies",
        "category": "DevOps",
        "views": 1120,
        "comments": 28,
        "date_published": "2024-09-21",
        "clicks_count": 400
    },
    {
        "title": "A Beginner's Guide to Machine Learning",
        "category": "AI",
        "views": 980,
        "comments": 18,
        "date_published": "2024-09-20",
        "clicks_count": 230
    },
    {
        "title": "Understanding Blockchain Technology",
        "category": "Blockchain",
        "views": 1350,
        "comments": 30,
        "date_published": "2024-09-19",
        "clicks_count": 510
    },
    {
        "title": "Building Mobile Apps with Flutter",
        "category": "Mobile Development",
        "views": 1100,
        "comments": 24,
        "date_published": "2024-09-18",
        "clicks_count": 330
    },
    {
        "title": "Cybersecurity: Best Practices in 2024",
        "category": "Security",
        "views": 780,
        "comments": 14,
        "date_published": "2024-09-17",
        "clicks_count": 220
    },
    {
        "title": "How to Use GraphQL in Web Applications",
        "category": "Development",
        "views": 610,
        "comments": 9,
        "date_published": "2024-09-15",
        "clicks_count": 120
    },
    {
        "title": "Top Cloud Platforms: AWS vs Azure vs GCP",
        "category": "Cloud Computing",
        "views": 940,
        "comments": 20,
        "date_published": "2024-09-14",
        "clicks_count": 265
    },
    {
        "title": "An Overview of Quantum Computing",
        "category": "Quantum Computing",
        "views": 1280,
        "comments": 33,
        "date_published": "2024-09-12",
        "clicks_count": 510
    },
    {
        "title": "Kubernetes: The Future of Container Orchestration",
        "category": "DevOps",
        "views": 1020,
        "comments": 17,
        "date_published": "2024-09-10",
        "clicks_count": 360
    },
    {
        "title": "SEO Strategies for 2024",
        "category": "Marketing",
        "views": 890,
        "comments": 11,
        "date_published": "2024-09-08",
        "clicks_count": 225
    },
    {
        "title": "Understanding REST APIs vs GraphQL",
        "category": "Development",
        "views": 760,
        "comments": 16,
        "date_published": "2024-09-07",
        "clicks_count": 185
    },
    {
        "title": "How to Implement CI/CD in Your Workflow",
        "category": "DevOps",
        "views": 1340,
        "comments": 26,
        "date_published": "2024-09-05",
        "clicks_count": 485
    },
    {
        "title": "Data Science with Python: A Comprehensive Guide",
        "category": "Data Science",
        "views": 1500,
        "comments": 35,
        "date_published": "2024-09-03",
        "clicks_count": 620
    },
    {
        "title": "An Introduction to Docker Containers",
        "category": "DevOps",
        "views": 1150,
        "comments": 20,
        "date_published": "2024-09-01",
        "clicks_count": 380
    },
    {
        "title": "Building Scalable Web Applications",
        "category": "Development",
        "views": 740,
        "comments": 14,
        "date_published": "2024-08-30",
        "clicks_count": 190
    },
    {
        "title": "Exploring Serverless Architecture",
        "category": "Cloud Computing",
        "views": 920,
        "comments": 18,
        "date_published": "2024-08-28",
        "clicks_count": 270
    },
    {
        "title": "Big Data Analytics: Tools and Techniques",
        "category": "Data Science",
        "views": 1360,
        "comments": 25,
        "date_published": "2024-08-27",
        "clicks_count": 540
    },
    {
        "title": "How to Get Started with Vue.js",
        "category": "Development",
        "views": 660,
        "comments": 10,
        "date_published": "2024-08-25",
        "clicks_count": 155
    },
    {
        "title": "AI in Finance: Trends and Applications",
        "category": "AI",
        "views": 1120,
        "comments": 27,
        "date_published": "2024-08-23",
        "clicks_count": 430
    },
    {
        "title": "The Role of AI in eCommerce",
        "category": "AI",
        "views": 1040,
        "comments": 22,
        "date_published": "2024-08-21",
        "clicks_count": 400
    },
    {
        "title": "Effective Microservices Design Patterns",
        "category": "Development",
        "views": 1340,
        "comments": 33,
        "date_published": "2024-08-20",
        "clicks_count": 520
    },
    {
        "title": "Using TypeScript with React for Large Projects",
        "category": "Development",
        "views": 980,
        "comments": 19,
        "date_published": "2024-08-19",
        "clicks_count": 260
    },
    {
        "title": "Edge Computing: The Next Frontier",
        "category": "Cloud Computing",
        "views": 920,
        "comments": 14,
        "date_published": "2024-08-17",
        "clicks_count": 290
    },
    {
        "title": "AI and Robotics: The Future of Automation",
        "category": "AI",
        "views": 1300,
        "comments": 31,
        "date_published": "2024-08-15",
        "clicks_count": 500
    },
    {
        "title": "Creating Progressive Web Apps (PWAs)",
        "category": "Development",
        "views": 770,
        "comments": 13,
        "date_published": "2024-08-14",
        "clicks_count": 180
    },
    {
        "title": "The Rise of No-Code Platforms",
        "category": "Technology",
        "views": 880,
        "comments": 21,
        "date_published": "2024-08-12",
        "clicks_count": 260
    },
    {
        "title": "Enhancing UX with User-Centered Design",
        "category": "Design",
        "views": 670,
        "comments": 8,
        "date_published": "2024-08-11",
        "clicks_count": 140
    },
    {
        "title": "The Ethics of AI: What You Need to Know",
        "category": "AI",
        "views": 1050,
        "comments": 25,
        "date_published": "2024-08-09",
        "clicks_count": 340
    },
    {
        "title": "Understanding Functional Programming in JavaScript",
        "category": "Programming",
        "views": 880,
        "comments": 18,
        "date_published": "2024-08-08",
        "clicks_count": 250
    },
    {
        "title": "Automating Workflows with Zapier and Integromat",
        "category": "Automation",
        "views": 920,
        "comments": 16,
        "date_published": "2024-08-06",
        "clicks_count": 280
    },
    {
        "title": "How to Create a Responsive Website",
        "category": "Design",
        "views": 1080,
        "comments": 20,
        "date_published": "2024-08-05",
        "clicks_count": 320
    },
    {
        "title": "Digital Transformation in Business",
        "category": "Business",
        "views": 870,
        "comments": 14,
        "date_published": "2024-08-03",
        "clicks_count": 240
    },
    {
        "title": "Understanding the Metaverse",
        "category": "Technology",
        "views": 1450,
        "comments": 38,
        "date_published": "2024-08-01",
        "clicks_count": 610
    },
    {
        "title": "Machine Learning Algorithms You Should Know",
        "category": "AI",
        "views": 1180,
        "comments": 30,
        "date_published": "2024-07-30",
        "clicks_count": 450
    },
    {
        "title": "An Introduction to Cryptocurrency Trading",
        "category": "Finance",
        "views": 900,
        "comments": 22,
        "date_published": "2024-07-28",
        "clicks_count": 270
    },
    {
        "title": "Cloud-Native Applications: What You Need to Know",
        "category": "Cloud Computing",
        "views": 1350,
        "comments": 27,
        "date_published": "2024-07-26",
        "clicks_count": 490
    },
    {
        "title": "Building a Secure Web Application",
        "category": "Security",
        "views": 1130,
        "comments": 19,
        "date_published": "2024-07-24",
        "clicks_count": 390
    },
    {
        "title": "The Impact of AI on Employment",
        "category": "AI",
        "views": 1270,
        "comments": 29,
        "date_published": "2024-07-23",
        "clicks_count": 480
    },
    {
        "title": "Best Practices for Database Optimization",
        "category": "Development",
        "views": 980,
        "comments": 18,
        "date_published": "2024-07-21",
        "clicks_count": 270
    }
]

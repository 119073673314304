import React from 'react'
import PageContainer from '../dashboard/PageContainer'

const NotFoundContent = () => {
    return (
        <div className='error-page'>
            <h3>Not found</h3>
            <p>The page you are looking for could not be found. This may be because resource has been deleted or the link is not available</p>
        </div>
    )
}

const NotFoundPage = () => {
    return (
        <PageContainer content={<NotFoundContent />} />
    )
}

export default NotFoundPage

import { Login02Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import api, { cleanedData, API_URL } from '../../../services/api'
import LoadingIcon from '../../LoadingIcon'
import CustomButton from '../../Button'
import axios from 'axios'

const LoginForm = ({ sessionExpired }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    // form
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleLogin = async () => {
        if (!email || !password) {
            setErrorMessage("Email and password are required")
            return
        }

        try {
            setIsLoading(true)
            const loginData = {
                "username": email,
                "password": password,
            }
            const response = await axios.post(`${API_URL}/accounts/auth/token/`, cleanedData(loginData))
            if (response.status === 200) {
                setSuccessMessage("Login successful")
                localStorage.setItem('access', response.data.access)
                localStorage.setItem("userData", JSON.stringify(response.data.user_info))
                setIsLoading(false)
                sessionExpired ? window.location.reload() : window.location.href = '/'

            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error while logging in')
            } else {
                setErrorMessage('Unknown error while logging in')
            }
            setIsLoading(false)
            console.log(error)
        }

    }


    return (
        <div className='form-container'>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <form>
                {/* login with email and password */}
                <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
            </form>
            {/* button to login */}
            <CustomButton
                isLoading={isLoading}
                buttonClasses={'primary-button full-button'}
                buttonText={'Login'}
                iconClass={<Login02Icon size={20} />}
                clickEvent={handleLogin} />
        </div>
    )
}

export default LoginForm

// format a date string line this 2024-10-11T17:20:46.772161+02:00 into human readable format.

export const formatDateTime = (dateString) => {
    if (dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleString('en-US', options);
    } else {
        return 'Invalid date format';
    }
}
import axios from "axios";

export const MEDIA_API_URL = process.env.REACT_APP_MEDIA_API_URL;

const mediaAPI = axios.create({
    baseURL: MEDIA_API_URL
})

mediaAPI.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem("access");
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        } return config
    }, (error) => {
        return Promise.reject(error)
    }
)

export default mediaAPI
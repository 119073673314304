import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { Cancel01Icon, PlusSignIcon } from 'hugeicons-react';


const CommentsContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    // Messages
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [comments, setComments] = useState([]);
    const [selectedComments, setSelectedComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    // Fetch comments when the component mounts
    useEffect(() => {
        // fetchComments();
    }, []);

    const handleSelectedComment = () => {
        // if comment is not in selectedComments, add it
        if (!selectedComments.includes(newComment)) {
            setSelectedComments([...selectedComments, newComment]);
            setNewComment("");
        }
    };

    const handleRemoveComment = (comment) => {
        setSelectedComments(selectedComments.filter((c) => c !== comment));
    };

    const fetchComments = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/comments/'); // Adjust this URL as needed
            if (response.status === 200) {
                setComments(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error while fetching comments');
            } else {
                setErrorMessage('Unknown error while fetching comments');
            }
            console.error(error);
        }
    };

    return (
        <div className="card">
            <h4>Comments</h4>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {/* Selected comments */}
            <div className="selected-comments form-choices">
                {selectedComments && selectedComments.map((comment, index) => (
                    <div className="selected-comment choice" key={index}>
                        {comment}
                        <Cancel01Icon color='red' size={18} onClick={() => handleRemoveComment(comment)} />
                    </div>
                ))}
            </div>

            {/* Comment Input with Datalist */}
            <form className="form-group">
                <label htmlFor="commentInput">Select or type a comment:</label>
                <div className="link-field">
                    <input
                        list="commentsList"
                        id="commentInput"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Start typing..."
                    />

                    <div className="button outline-button" onClick={handleSelectedComment}>
                        <PlusSignIcon size={18} />
                    </div>
                </div>
                <datalist id="commentsList">
                    {comments.map(comment => (
                        <option key={comment.id} value={comment.content} />
                    ))}
                </datalist>
            </form>

            {/* Show loading state */}
            {isLoading && <div>Loading comments...</div>}
        </div>
    );
};

export default CommentsContainer;

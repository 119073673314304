import { FloppyDiskIcon } from 'hugeicons-react'
import React from 'react'
import CustomButton from '../../Button'

const NewCommentForm = () => {
    return (
        <div className='form-container'>
            <form>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" className="form-control" placeholder="Enter your name" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" className="form-control" placeholder="Enter your email" />
                </div>
                <div className="form-group">
                    <label htmlFor="comment">Comment:</label>
                    <textarea id="comment" className="form-control" placeholder="Enter comment" rows="5"></textarea>
                </div>
            </form>
            <CustomButton
                iconClass={<FloppyDiskIcon />}
                buttonClasses={'primary-button'}
                buttonText={'Publish comment'} />
        </div>
    )
}

export default NewCommentForm

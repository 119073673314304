import React from 'react'
import LoginForm from '../../componets/forms/auth/LoginForm'

const LoginPage = () => {
    return (
        <div>
            <LoginForm />
        </div>
    )
}

export default LoginPage


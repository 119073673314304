import React, { useState } from 'react'

const CustomSelectInput = ({ options, selectedOptions, handleSelectedOptions, placeholder }) => {
    const [showOptions, setShowOptions] = useState(false)
    const [selectedOption, setSelectedOption] = useState("")
    const [filteredOptions, setFilteredOptions] = useState([])

    const toggleOptions = () => setShowOptions(!showOptions)
    const handleSelectedOption = (option) => {
        handleSelectedOptions(option)

        setSelectedOption(option.label)
        setShowOptions(false)
    }

    // handle filter options by label
    const handleFilterOptions = (filterString) => {
        setSelectedOption(filterString)
        const searchValue = filterString.toLowerCase()
        if (!filterString) {
            setFilteredOptions(options)
            return
        }
        setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(searchValue)))
    }
    return (
        <div className='select'>
            <div className="form-group">
                <input value={selectedOption} onClick={toggleOptions} onChange={(e) => handleFilterOptions(e.target.value)} type="text" name="" id="" placeholder={placeholder || 'Select option'} />
            </div>
            {
                showOptions &&
                <div className="options">
                    {filteredOptions && filteredOptions.length > 0
                        ? filteredOptions.map((option, index) => (
                            <div onClick={() => handleSelectedOption(option)} key={index} className="option">
                                <span htmlFor={option.value}>{option.label}</span>
                            </div>
                        )) : <div className="not-found">
                            No author with name {selectedOption} found
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default CustomSelectInput

import React, { useEffect, useState } from 'react'
import PageContainer from '../PageContainer'
import CustomButton from '../../../componets/Button'
import { ArrowLeft01Icon, ArrowRight01Icon, Cancel01Icon, PlusSignIcon, Search01Icon, SquareIcon } from 'hugeicons-react'

import '../../../assets/scss/articles/articles.css'
import { articles } from '../../../constants/articles'
import NewCategoryForm from '../../../componets/forms/categories/NewCategoryForm'
import api from '../../../services/api'

const CategoriesPageContent = () => {
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [categories, setCategories] = useState([]);
    const itemsPerPage = 10;  // Number of items per page

    // Calculate the total number of pages
    const totalPages = Math.ceil(articles.length / itemsPerPage);

    // Get current items based on currentPage
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = categories?.slice(indexOfFirstItem, indexOfLastItem);
    const [showNewCategoryForm, setShowNewCategoryForm] = useState(false);

    // Function to toggle new category form visibility
    const toggleNewCategoryForm = () => {
        setShowNewCategoryForm(!showNewCategoryForm);
    }

    // Function to handle page change
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await api.get(`/categories/`)
            if (response.status === 200) {
                console.log(response)
                setCategories(response.data)
            }
        } catch (error) {
            console.error(error)
        }
    }



    useEffect(() => {
        fetchCategories()
    }, [])
    return (
        <div className='articles-page'>
            <h2>Categories</h2>

            <div className="search-filters">
                <div className="search">
                    <input type="text" placeholder="Search categories..." />
                    <button className='outline-button'>
                        Search
                        <Search01Icon size={18} />

                    </button>
                </div>
                <div className="filters-create">
                    <CustomButton
                        clickEvent={toggleNewCategoryForm}
                        buttonClasses={'primary-button'}
                        iconClass={<PlusSignIcon size={20} />}
                        buttonText={'Create a category'}
                    />
                </div>
            </div>

            <div className="articles-list">
                {/* table starts */}
                <table>
                    <thead>
                        <tr>
                            <th><SquareIcon /> </th>
                            <th>ID</th>
                            <th>Name</th>
                            <th>link</th>

                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((cat, index) => (
                            <tr key={index}>
                                <td><SquareIcon /></td>
                                <td>{cat.id}</td>
                                <td>{cat.name}</td>
                                <td>{cat.slug}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* table ends */}

                {/* Pagination controls */}
                <div className="pagination">

                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="primary-button small-button">
                        <ArrowLeft01Icon size={18} />
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="primary-button small-button">
                        Next
                        <ArrowRight01Icon size={18} />
                    </button>
                </div>
            </div>
            {
                showNewCategoryForm &&
                <div className="pop-up">
                    <div className="popup-content">
                        <h2>New category</h2>
                        <Cancel01Icon onClick={toggleNewCategoryForm} size={24} className='close-icon' />
                        <NewCategoryForm />
                    </div>
                </div>
            }
        </div>
    );
};


const CategoriesPage = () => {
    return (
        <PageContainer content={<CategoriesPageContent />} />
    )
}

export default CategoriesPage

import React from 'react'
import LoginForm from '../../componets/forms/auth/LoginForm'

const SessionExpiredPage = () => {
    return (
        <div className='full-page auth-page'>
            <div className='container'>
                <div className='login-form'>
                    <h2>Your session expired</h2>
                    <p>Login to continue accessing resources on KFinancial</p>
                    <LoginForm sessionExpired={true} />
                </div>
            </div>
        </div>
    )
}

export default SessionExpiredPage

// create names initials by splitting a full name into parts, then get the first ans last parts' first letter

export const nameInitials = (fullName) => {
    if (fullName) {
        const nameParts = fullName.split(' ');
        const firstLetter = nameParts[0][0].toUpperCase();
        const lastLetter = nameParts[nameParts.length - 1][0].toUpperCase();
        return `${firstLetter} ${lastLetter}`;
    } else {
        return '';
    }
}
import api from "./api";

export const handleSearchByTitle = async (title) => {
    if (title) {
        try {
            const response = await api.get(`/dashboard/blogs/search/${title}/`);
            if (response.status === 200) {
                // Return an object containing the result, success status, and message
                return {
                    results: response.data,
                    success: true,
                    message: 'Success'
                };

            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                return {
                    results: [],
                    success: false,
                    message: error.response.data.message || error.response.data.error || 'Error while fetching articles'
                };
            }
            return {
                results: [],
                success: false,
                message: 'Unknown error while fetching articles'
            };
        }
    } else {
        return {
            results: [],
            success: false,
            message: 'No search performed'
        };
    }
};

import React, { useEffect, useState } from 'react'
import CustomButton from '../../Button'
import { FloppyDiskIcon } from 'hugeicons-react'
import api from '../../../services/api'

const NewPodcastForm = () => {
    // form fields
    // title
    // host
    // description
    // thumbnail
    // episodes
    // category
    // rating
    // url
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState("")
    const [host, setHost] = useState("")
    const [description, setDescription] = useState("")
    const [thumbnail, setThumbnail] = useState("")
    const [episodes, setEpisodes] = useState("")
    const [category, setCategory] = useState("")
    const [rating, setRating] = useState("")
    const [url, setUrl] = useState("")

    // messages
    const [successMessage, setSuccessMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)


    const handleNewPodcast = async () => {
        // clear messages
        setSuccessMessage("")
        setErrorMessage("")
        // validate form data
        if (!title || !url || !category || !thumbnail) {
            setErrorMessage("Please fill in all required fields")
            return
        }
        // create new podcast object
        const podcastData = {
            "title": title,
            "host": host,
            "description": description,
            "thumbnail": thumbnail,
            "episodes": episodes,
            "category": category,
            "rating": rating,
            "url": url,
        }
        // add new podcast to podcastsList
        try {
            setIsLoading(true)
            const response = await api.post(`/dashboard/podcasts/create/`, podcastData)
            if (response.status === 201) {
                setSuccessMessage("Podcast created successfully")
                // reset form fields
                setTitle("")
                setHost("")
                setDescription("")
                setThumbnail("")
                setEpisodes("")
                setCategory("")
                setRating("")
                setUrl("")
                setIsLoading(false)
                window.location.reload()
            }
        } catch (error) {

            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || 'Error creating podcast')
            } else {
                setErrorMessage('Unknown error occurred while creating the podcast')
            }
            console.log(error)
            setIsLoading(false)
        }
        // close form
        // reset form fields
    }

    useEffect(() => {

        const fetchAuthors = async () => {
            setIsLoading(true);
            try {
                const response = await api.get(`/dashboard/authors/`)
                if (response.status === 200) {
                    setAuthors(response.data);
                }
            } catch (error) {
                console.error(error);
                setErrorMessage("Failed to fetch authors. Please try again later.");
            } finally {
                setIsLoading(false);
            }
        }
        fetchAuthors();

    }, []);

    useEffect(() => {
        setIsLoading(true)
        const fetchCategories = async () => {
            try {
                const response = await api.get(`/dashboard/categories/`)
                if (response.status === 200) {
                    setCategories(response.data);
                    setIsLoading(false);
                }
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.message || error.response.data.error || 'Error while fetching categories');
                } else {
                    setErrorMessage('Unknown error while fetching categories');
                    console.error(error);
                    setIsLoading(false);
                }
            }
        }
        fetchCategories();
    }, []);

    return (
        <div className='form-container'>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <form>
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input type="text" id="title" className="form-control" placeholder="Enter podcast title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className="half">
                    <div className="form-group">
                        <label htmlFor="host">Host:</label>
                        {authors && authors.length > 0 ? (
                            <select
                                name="host"
                                id="host"
                                defaultValue={""}
                                onChange={(e) => {
                                    const selectedHost = authors.find(author => author.id === Number(e.target.value));
                                    setHost(selectedHost);
                                }}
                            >
                                <option value="">Select host</option>
                                {authors.map(author => (
                                    <option
                                        key={author.id}
                                        value={author.id}
                                    >
                                        {author.first_name} {author.last_name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                disabled
                                type="text"
                                id="host"
                                className="form-control"
                                placeholder="Error getting hosts"
                            />
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="category">Category:</label>
                        {categories && categories.length > 0 ? (
                            <select
                                name="category"
                                id="category"
                                defaultValue={""}
                                onChange={(e) => {
                                    const selectedCategory = categories.find(category => category.id === Number(e.target.value));
                                    setCategory(selectedCategory);
                                }}
                            >
                                <option key={0} value="">Select category</option>
                                {categories.map(category => (
                                    <option
                                        key={category.id}
                                        value={category.id}
                                    >
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                disabled
                                type="text"
                                id="category"
                                className="form-control"
                                placeholder="Error getting categories"
                            />
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea id="description" className="form-control" placeholder="Enter podcast description" value={description} onChange={(e) => setDescription(e.target.value)} rows="5"></textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="thumbnail">Featured image:</label>
                    <input type="link" id="thumbnail" className="form-control" placeholder="Enter podcast image URL" value={thumbnail} onChange={(e) => setThumbnail(e.target.value)} />
                </div>
                <div className="half">
                    <div className="form-group">
                        <label htmlFor="episodes">Episodes:</label>
                        <input type="number" id="episodes" className="form-control" placeholder="Enter number of episodes" value={episodes} onChange={(e) => setEpisodes(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="rating">Rating:</label>
                        <input type="number" id="rating" className="form-control" placeholder="Enter podcast rating" value={rating} onChange={(e) => setRating(e.target.value)} />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="url">URL:</label>
                    <input type="link" id="url" className="form-control" placeholder="Enter podcast URL" value={url} onChange={(e) => setUrl(e.target.value)} />
                </div>
            </form>
            <CustomButton
                clickEvent={handleNewPodcast}
                isLoading={isLoading}
                iconClass={<FloppyDiskIcon />}
                buttonClasses={'primary-button'}
                buttonText={'Save podcast'} />
        </div>
    )
}

export default NewPodcastForm

import React, { useEffect, useRef, useState } from 'react'
import PageContainer from '../../dashboard/PageContainer'
import { Editor } from '@tinymce/tinymce-react';
import api, { cleanedData, TINY_KEYS } from '../../../services/api';
import { Cancel01Icon, Copy01Icon, FloppyDiskIcon, PlusSignIcon, RepeatIcon } from 'hugeicons-react';
import CustomButton from '../../../componets/Button';
import { categoriesList } from '../../../constants/categories';
import CategoriesContainer from './CategoriesContainer';
import AuthorsContainer from './AuthorsContainer';
import TagsContainer from './TagsContainer';
import CommentsContainer from './CommentsContainer';
import ProfilePictureContainer from './profilePictureContainer';
import { generateLink } from '../../../services/generateLink';
import mediaAPI from '../../../services/mediaAPI';

// categories, authors, contributors, tags, comments


export const ArticleContainer = ({ title, setTitle, slug, setSlug, content, setContent, excerpt, setExcerpt }) => {

    const editorRef = useRef(null);

    const handleEditorInit = (evt, editor) => {
        editorRef.current = editor;
    };

    const handleEditorChange = (content, editor) => {
        setContent(content);
    };

    const handleSlug = (title) => {
        setTitle(title);
        setSlug(generateLink(title));
    }
    const images_upload_handler = async (blobInfo, progress) => {
        try {
            const formData = new FormData();
            formData.append('files', blobInfo.blob(), blobInfo.filename());
            formData.append('folder', 'your-folder-name');

            const response = await mediaAPI.post(
                `/upload/`,
                formData,

            );
            console.log('image upload response: ', response);
            return response.data.urls;
        } catch (error) {
            throw new Error('Image upload failed: ' + error.message);
        }
    };


    return (
        <div className='article-content'>
            <form >
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input type="text" id="title" value={title} onChange={(e) => handleSlug(e.target.value)} placeholder='Article title' />
                </div>
                <div className="form-group">
                    <label htmlFor='link'>Link:</label>
                    <small>This link is  preview. A real link will will be generated after you publish or save draft</small>
                    <div className="link-field">
                        <input value={slug} disabled type='text' id='link' className='form-control' placeholder='Link is auto generated' />
                        <div type='button' className="button outline-button">< Copy01Icon size={18} />Copy</div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="excerpt">Excerpt:</label>
                    <textarea id="excerpt" value={excerpt} onChange={(e) => setExcerpt(e.target.value)} placeholder='Article excerpt' rows='3' />
                </div>
                <div className="form-group">
                    <label htmlFor="content">Content:</label>
                    <Editor
                        apiKey={TINY_KEYS}
                        onInit={handleEditorInit}
                        value={content}
                        init={{
                            height: 500,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | image | removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            images_upload_handler: images_upload_handler
                        }}
                        onEditorChange={handleEditorChange}
                    />
                </div>
            </form>
            <CommentsContainer />
        </div>
    )
}


const NewArticleNewArticleContent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    const [title, setTitle] = useState("")
    const [featuredImage, setFeaturedImage] = useState({})
    const [slug, setSlug] = useState("");
    const editorRef = useRef(null);
    const [content, setContent] = useState("");
    const [excerpt, setExcerpt] = useState("");

    const articleData = {
        "categories": selectedCategories,
        "authors": selectedAuthors,
        "title": title,
        "slug": slug,
        "content": content,
        "excerpt": excerpt,
        "featured_image": featuredImage.url, // can be added later
        "tags": [], // can be added later
        "comments": [], // can be added later
        "blog_status": "draft",

    }

    const handleNewArticle = async () => {
        setErrorMessage("")
        setSuccessMessage("")
        try {
            const response = await api.post(`/dashboard/blogs/create/`, cleanedData(articleData))
            if (response.status === 201) {
                setSuccessMessage("Article created successfully")
                setSlug(response.data.slug)
                console.log(response.data)

                setTimeout(() => {
                    window.location.href = `/articles/${response.data.slug}/update/`
                }, 2000);
            }
        } catch (error) {
            if (error.response) {
                setErrorMessage(error.response.data.message || error.response.data.error || "Error creating article")
            } else {
                setErrorMessage("Unknown error occurred while creating the article")
            }
            console.log(error)
        }
    }
    return (
        <div className="new-article-page">
            <h2>New article</h2>
            {
                errorMessage && <div className="error-message">{errorMessage}</div>
            }
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            <div className='new-article'>
                <ArticleContainer
                    title={title}
                    setTitle={setTitle}
                    slug={slug}
                    setSlug={setSlug}
                    content={content}
                    setContent={setContent}
                    excerpt={excerpt}
                    setExcerpt={setExcerpt}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                />
                <div className="other-content-cards">
                    <div className="buttons">
                        {/* save draft of publish */}
                        <CustomButton
                            buttonClasses={'primary-button'}
                            iconClass={<FloppyDiskIcon size={20} />}
                            buttonText={'Publish'}
                            clickEvent={handleNewArticle}
                        />
                        {/* save draft */}
                        <CustomButton
                            buttonClasses={'outline-button'}
                            iconClass={<RepeatIcon size={20} />}
                            buttonText={'Save Draft'}
                        />

                    </div>
                    <ProfilePictureContainer setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage} featuredImage={featuredImage} setFeaturedImage={setFeaturedImage} />
                    <CategoriesContainer selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />
                    <AuthorsContainer selectedAuthors={selectedAuthors} setSelectedAuthors={setSelectedAuthors} />
                    {/* <ContributorsContainer /> */}
                    {/* <TagsContainer /> */}

                </div>
            </div>
        </div>
    )
}

const NewArticlePage = () => {
    return (
        <PageContainer content={<NewArticleNewArticleContent />} />
    )
}

export default NewArticlePage

import { Login02Icon } from 'hugeicons-react'
import React from 'react'
import LoadingIcon from './LoadingIcon'

const CustomButton = ({ clickEvent, isLoading, full, buttonClasses, buttonText, iconClass }) => {
    return (
        <button disabled={isLoading} className={buttonClasses} onClick={clickEvent} type="type">
            {
                isLoading ? <LoadingIcon /> : <>{iconClass} {buttonText || 'Add button text'}</>
            }
        </button>
    )
}

export default CustomButton

import React, { useState } from 'react'
import PageContainer from '../PageContainer'
import CustomButton from '../../../componets/Button'
import { ArrowLeft01Icon, ArrowRight01Icon, Cancel01Icon, PlusSignIcon, SquareIcon } from 'hugeicons-react'

import '../../../assets/scss/articles/articles.css'
import { articles } from '../../../constants/articles'
import NewTagForm from '../../../componets/forms/tags/NewTagForm'

const TagsPageContent = () => {
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;  // Number of items per page

    // Calculate the total number of pages
    const totalPages = Math.ceil(articles.length / itemsPerPage);

    // Get current items based on currentPage
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = articles.slice(indexOfFirstItem, indexOfLastItem);

    // show new tags form
    const [showNewTagForm, setShowNewTagForm] = useState(false)

    const toggleNewTagForm = () => {
        setShowNewTagForm(!showNewTagForm);
    }

    // Function to handle page change
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='articles-page'>
            <h2>Tags</h2>

            <div className="search-filters">
                <div className="search">
                    <input type="text" placeholder="Search tags..." />
                    <button>Search</button>
                </div>
                <div className="filters-create">
                    <CustomButton
                        clickEvent={toggleNewTagForm}
                        buttonClasses={'primary-button'}
                        iconClass={<PlusSignIcon size={20} />}
                        buttonText={'Create a tag'}
                    />
                </div>
            </div>

            <div className="articles-list">
                {/* table starts */}
                <table>
                    <thead>
                        <tr>
                            <th><SquareIcon /> </th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Views</th>
                            <th>Comments</th>
                            <th>Date Published</th>
                            <th>Clicks Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((article, index) => (
                            <tr key={index}>
                                <td><SquareIcon /></td>
                                <td>{article.title}</td>
                                <td>{article.category}</td>
                                <td>{article.views}</td>
                                <td>{article.comments}</td>
                                <td>{article.date_published}</td>
                                <td>{article.clicks_count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* table ends */}

                {/* Pagination controls */}
                <div className="pagination">

                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="primary-button small-button">
                        <ArrowLeft01Icon size={18} />
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="primary-button small-button">
                        Next
                        <ArrowRight01Icon size={18} />
                    </button>
                </div>
            </div>

            {
                showNewTagForm && <div className="pop-up">
                    <div className="popup-content">
                        <h2>New tag</h2>
                        <Cancel01Icon onClick={toggleNewTagForm} size={24} className='close-icon' />
                        <NewTagForm />
                    </div>
                </div>
            }
        </div>
    );
};


const TagsPage = () => {
    return (
        <PageContainer content={<TagsPageContent />} />
    )
}

export default TagsPage

import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/dashboard/Dashboard';
import LoginPage from './pages/auth/Login';
import ProtectedRoute from './services/protectedRoute';
import './assets/scss/main/main.css'
import CategoriesPage from './pages/dashboard/categories/categories';
import TagsPage from './pages/dashboard/tags/Tags';
import CommentsPage from './pages/dashboard/comments/Comments';
import NotFoundPage from './pages/errorPages/NotFound';
import ArticlesPage from './pages/dashboard/articles/Articles';
import NewArticlePage from './pages/dashboard/articles/NewArticle';
import UpdateArticlePage from './pages/dashboard/articles/UpdateArticle';
import PodcastsPage from './pages/dashboard/podcasts/PodcastsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
        <Route exact path="*" element={<ProtectedRoute><NotFoundPage /></ProtectedRoute>} />
        {/* articles */}
        <Route exact path="/articles/" element={<ProtectedRoute><ArticlesPage /></ProtectedRoute>} />
        <Route exact path="/articles/create/" element={<ProtectedRoute><NewArticlePage /></ProtectedRoute>} />
        <Route exact path="/articles/:blogSlug/update/" element={<ProtectedRoute><UpdateArticlePage /></ProtectedRoute>} />
        {/* categories */}
        <Route exact path="/articles/categories/" element={<ProtectedRoute><CategoriesPage /></ProtectedRoute>} />
        {/* tags */}
        <Route exact path="/articles/tags/" element={<ProtectedRoute><TagsPage /></ProtectedRoute>} />
        {/* comments */}
        <Route exact path="/articles/comments/" element={<ProtectedRoute><CommentsPage /></ProtectedRoute>} />
        {/* podcasts */}
        <Route exact path="/podcasts/" element={<ProtectedRoute><PodcastsPage /></ProtectedRoute>} />

        <Route exact path="/auth/login/" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
